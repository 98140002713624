<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>
    <word ref="word"></word>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="question_list_wrap box_sizing">
<!--        <el-button style="margin-bottom: 20px" @click="$router.go(-1)"-->
<!--          >返回</el-button-->
<!--        >-->

        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between">
<!--          <div class="question_list_left flex_1 flex_box flex_align_center">-->
<!--            <div class="question_type_labels box_sizing">MCM</div>-->
<!--            <div class="question_type_name">{{ detail.topic_type_name }}</div>-->
<!--            &lt;!&ndash; <div class="question_type_number">40/200</div> &ndash;&gt;-->
<!--          </div>-->
<!--          <img-->
<!--            src="../../assets/images/guanbi.png"-->
<!--            class="question_type_right"-->
<!--            alt="返回上一页"-->
<!--            @click="goBack"-->
<!--          />-->
          <navtitle :topic_id="id" title="阅读多选" pinyin="MCM_R" page="questionType7"></navtitle>

        </div>

        <div class="topic_wrap">
          <div class="topic_type_name">{{ detail.name }}</div>
          <div class="topic_type_info box_sizing flex_box flex_align_center">
            <div class="topic_type_info_time">{{ detail.createtime_text }}</div>
            <div class="question_lian_shoucang">
              <img class="question_list_jiangjieimg" v-if="!detail.collect || detail.collect == 1" @click="collowCilck()"
                src="../../assets/images/shoucang1.png" alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 2" @click="collowCilck()" src="../../assets/images/shoucang2.png"
                alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 3" @click="collowCilck()" src="../../assets/images/shoucang3.png"
                alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 4" @click="collowCilck()" src="../../assets/images/shoucang4.png"
                alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 5" @click="collowCilck()" src="../../assets/images/shoucang5.png"
                alt="" />
              <div class="question_list_sc_box" v-if="fav_show">
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(1)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang1.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(2)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang2.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(3)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang3.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(4)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang4.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(5)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang5.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <img
              src="../../assets/images/type_fenxiang.png"
              class="topic_type_info_fenxiang"
              alt=""
            /> -->
          </div>
          <div class="topic_type_title">{{ detail.describe }}</div>
          <!-- 题目区域 -->
          <div class="topic_type_timu_box box_sizing">
            <span
              v-for="(item, index) in filteredShowText"
              :key="index"
              class="panding_change"
              @click="dianjiClick(item)"
              v-html="item"
            ></span>
            <!--            <span v-for="(item, index) in detail.show_text" :key="index" style="white-space: pre-wrap;-->
            <!--">{{ item }}&nbsp;</span>-->
          </div>
          <!-- <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-if="daojishiTime > 0">{{daojishiTime}}s后开始答题</div> -->
          <div class="dati_time box_sizing flex_box flex_align_center justify_end">答题时间：{{datiTime}}</div>

          <div class="topic_type_answer_box box_sizing">
            <div class="my_questins4 flex_box">
              <div class="my_questins4_title flex_box" v-if="detail.extend">
                {{ detail.extend.topic }}
                <!-- <div>{{detail.extend.daan}}</div> -->
                <!-- . -->
              </div>
              <div class="my_questins4_box flex_box" v-if="detail.extend">
                <div
                  class="my_questins4_list flex_box hand_change"
                  v-for="(item, index) in detail.extend.extend_MA"
                  :key="index"
                  @click="selectDaan(index)"
                >
                  <img
                    v-if="item.select == 0"
                    src="../../assets/images/checkbox_nochoose.png"
                  />
                  <img
                    v-else-if="item.select == 1"
                    src="../../assets/images/checkbox_choose.png"
                  />
                  <img
                    v-else-if="item.select == 2"
                    src="../../assets/images/zhengque_Icon@2x.png"
                  />
                  <img
                    v-else-if="item.select == 3"
                    src="../../assets/images/guanbi_icon@2x.png"
                  />
                  <div :class="[item.select == 2 ? 'my_questins4_color' : '']">
                    {{ item.key }}. {{ item.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="dati_time box_sizing flex_box flex_align_center justify_end">答题时间：{{datiTime}}</div> -->

          <div
            class="topic_type_answer_bottom box_sizing flex_box flex_align_center flex_justify_between"
          >
            <div
              class="topic_type_answer_botleft box_sizing flex_box flex_align_center"
            >
              <div class="topic_type_answer_botbtn" @click="submitTopic">
                完成
              </div>
              <div class="topic_type_answer_botbtn now" @click="chongzuo">
                重做
              </div>
              <div class="">
                <span style="margin-right: 3px">答案：</span>
                <el-tooltip content="解析答案">
                  <el-switch v-model="showSwitch" active-color="#f6704b"	></el-switch>
                </el-tooltip>
              </div>
<!--              <div class="topic_type_answer_swich box_sizing flex_box flex_align_center">-->
<!--                <img-->
<!--                  class="topic_type_answer_swich_img"-->
<!--                  @click="shunxu = 2"-->
<!--                  src="../../assets/images/shunxuzheng.png"-->
<!--                  v-if="shunxu == 1"-->
<!--                  alt=""-->
<!--                />-->
<!--                <img-->
<!--                  v-if="shunxu == 2"-->
<!--                  @click="shunxu = 1"-->
<!--                  class="topic_type_answer_swich_img"-->
<!--                  src="../../assets/images/shunxufan.png"-->
<!--                  alt=""-->
<!--                />-->
<!--                <div class="topic_type_answer_swich_txt">顺序</div>-->
<!--              </div>-->
            </div>
            <div
              class="topic_type_answer_botcenter box_sizing flex_box flex_align_center"
            >
              <div
                class="topic_type_answer_botcenter box_sizing flex_box flex_align_center"
              >
                <div
                  class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center"
                  @click="seeDaFen"
                >
                  <img
                    src="../../assets/images/dafen_icon.png"
                    class="topic_type_answer_botcenter_img"
                    alt=""
                  />
                  <div class="topic_type_answer_botcenter_txt">查看打分</div>
                </div>
                <div
                  class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center"
                  @click="shenQingPiGai"
                >
                  <img
                    src="../../assets/images/shenqing_icon.png"
                    class="topic_type_answer_botcenter_img"
                    alt=""
                  />
                  <div class="topic_type_answer_botcenter_txt">申请批改</div>
                </div>
              </div>
            </div>
            <div
              class="topic_type_answer_botright box_sizing flex_box flex_align_center"
            >
              <div class="topic_type_answer_botbtn tihao" @click="shangYiTi">上一题</div>
              <div class="topic_type_answer_botbtn" @click="xiaYiTi">
                下一题
              </div>
            </div>
          </div>
          <div v-show="showSwitch" class="common_content">
            <!-- <div style="font-size: 18px;margin-bottom: 5px">答案：</div> -->
            <div v-html="detail.show_tape_text"></div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>
    <div class="model" v-if="status">
      <div class="modelContent box_sizing">
        <div class="title">阅读多选</div>
        <!-- <div class="teacher flex_box flex_align_center box_sizing flex_justify_between">
                    <div class="flex_box flex_align_center" style="width: 40%;">
                        <img src="../../assets/images/play.png" alt="" >
                        <div class="play box_sizing">
                            <div class="topic_type1_luyin progress">
                                <div class="topic_type1_luyin1" style="width: 50%"></div>
                            </div>
                            <div class="time">00:01/01:23</div>
                        </div>
                    </div>
                    <div class="flex_align_center flex_box yin box_sizing" >
                        <img src="../../assets/images/laba.png" alt="" >
                        <div class="topic_type1_luyin progress">
                            <div class="topic_type1_luyin1" style="width: 50%"></div>
                        </div>
                    </div>

                </div> -->
        <div class="flex-warp flex_box onebox">
          <div
            class="one flex_box flex_align_center box_sizing flex-wrap"
            v-if="obj.content_score"
          >
            <div class="neirong flex_box">内容</div>
            <div class="fenshu">
              <div>得分：{{ obj.content_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.content_score.full }}分
              </div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.format_score"
          >
            <div class="neirong flex_box">格式</div>
            <div class="fenshu">
              <div>得分：{{ obj.format_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.format_score.full }}分
              </div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.structure"
          >
            <div class="neirong flex_box">发展结构</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.grammar"
          >
            <div class="neirong flex_box">语法</div>
            <div class="fenshu">
              <div>得分：{{ obj.grammar.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.grammar.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.structure"
          >
            <div class="neirong flex_box">语言范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.wordScore"
          >
            <div class="neirong flex_box">词汇范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.wordScore.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.wordScore.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.spelling"
          >
            <div class="neirong flex_box">拼写</div>
            <div class="fenshu">
              <div>得分：{{ obj.spelling.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.spelling.full }}分</div>
            </div>
          </div>
        </div>
        <div class="zuoda box_sizing">
          <div class="zuodatitle box_sizing">作答内容</div>
          <div class="zuodacont">{{ obj.content }}</div>
        </div>
        <div class="zuoda box_sizing">
          <div class="zuodatitle box_sizing">正确答案</div>
          <div class="zuodacont">{{ obj.daan }}</div>
        </div>
        <div class="cancel" @click="status = false">
          <img src="../../assets/images/login_close.png" alt="" />
        </div>

        <!-- <div class="ping">
                    <img src="../../assets/images/wenhao.png" alt="">
                    <div>评分细则</div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import "@/css/questionList.css";
import "@/css/questiontype.css";
import "@/css/pagePing.css";
import Navtitle from "@/components/navtitle.vue";
import Word from "@/components/word.vue";
var _this, intervalTime;
export default {
  components: {
    Navtitle,
    headers,
    footers,
    left,
    right,
    scrollpage,
    Word
  },
  data() {
    return {
      showSwitch: false,
      topicExerciseId: null,
      id: "",
      detail: {},
      name: "",
      ansId: "",
      time: 0,
      list: [],
      status: false,
      obj: {},
      fav_show: false,
      huida: false,
      shunxu: 2,
      datiTime: "00:00:00",
      bofangStatus: false,
      daojishiTime: 40,
      audio: null,
      type: 0
    };
  },
  created() {
    _this = this;
    _this.getParams();
    _this.daojishi();
    // _this.daojishiBack()
  },
  computed: {
    filteredShowText() {
      return this.detail.show_text.filter(item => item !== '<br/>');
    }
  },
  methods: {
    //选择收藏哪个
    collowCilck() {
      _this.fav_show = !_this.fav_show
      console.log('12313',_this.fav_show);
    },
    // 收藏题目
    collectFun(type) {
      // console.log(i)
      let params = {
        topic_id: _this.detail.id,
        type: type,
      };

      if (localStorage.getItem("token")) {
        _this.$axios
          .post("exercise.topic_collect/collect", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.detail.collect = type;
              _this.fav_show = false;
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },
    copyDomain() {
      if (_this.topicExerciseId == null) {
        this.$message.error("请申请批改后复制链接");
        return;
      }
      _this.$copyText(_this.$global.baseUrl + "&id=" + _this.topicExerciseId);
      this.$message.success("地址复制成功");
    },
    // 进入页面倒计时40秒开始录音
    daojishiBack() {
      let daojishiBo = setInterval(() => {
        if (_this.daojishiTime <= 0) {
          clearInterval(daojishiBo);
          _this.audio.src = require("../../assets/di.mp3");
          let playPromise;
          playPromise = _this.audio.play();
          _this.playStatus = true;
          if (playPromise) {
            playPromise
              .then(() => {
                // 音频加载成功
                // 音频的播放需要耗时
              })
              .catch((e) => {
                // 音频加载失败
                console.error(e);
              });
          }
          _this.getTime();
        } else {
          _this.daojishiTime--;
        }
      }, 1000);
    },
    // 点击事件
    dianjiClick(item) {
      console.log(item);
      if(!item || item == '&nbsp;') {return;}
      this.$refs.word.word_text = item;
      this.$refs.word.getWord();
    },

    // 返回上一页
    goBack() {
      _this.$router.back();
    },

    // 上一题
    shangYiTi() {
      var params = {
        topic_id: _this.id,
        type: _this.detail.topic_type_id,
        exercise_status: localStorage.getItem('lianxiId'),
        topic_collect: localStorage.getItem('collectId'), //收藏 之前添加传的
        pinlvId: localStorage.getItem('pinlvId'),
        tags: localStorage.getItem('tag_id'),
        high_type: _this.type,
        topic_lecture_id:
          localStorage.getItem('jiangjieId') == 0 ? undefined : localStorage.getItem('jiangjieId') == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      _this.$axios
        .post("exercise.topic/lastTopic", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.id = res.data.topic_id;
            this.$router.replace({
              path: "questionType7?id=" + res.data.topic_id + "&name=阅读多选*type="+_this.type,
            });
            location.reload();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          _this.$message.error(err.msg);
        });
    },
    // 下一题
    xiaYiTi() {
      var params = {
        topic_id: _this.id,
        type: _this.detail.topic_type_id,
        exercise_status: localStorage.getItem('lianxiId'),
        topic_collect: localStorage.getItem('collectId'), //收藏 之前添加传的
        pinlvId: localStorage.getItem('pinlvId'),
        tags: localStorage.getItem('tag_id'),
        high_type: _this.type,
        topic_lecture_id:
          localStorage.getItem('jiangjieId') == 0 ? undefined : localStorage.getItem('jiangjieId') == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      _this.$axios
        .post("exercise.topic/nextTopic", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.id = res.data.topic_id;
            this.$router.replace({
              path: "questionType7?id=" + res.data.topic_id + "&name=阅读多选&type="+_this.type,
            });
            location.reload();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          _this.$message.error(err.msg);
        });
    },

    // 申请批改
    shenQingPiGai() {
      if (_this.huida) {
        var params = {
          topic_exercise_id: _this.topicExerciseId,
        };
        _this.$axios
          .post("exercise.topic_exercise/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.$message.success("您已提交申请");
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      } else {
        _this.$message.error("您还没答题，请先进行答题");
      }
    },

    // 查看打分
    seeDaFen() {
      if (_this.huida) {
        _this.status = true;
      } else {
        _this.$message.error("您还没答题，请先进行答题");
      }
    },

    // 重做
    chongzuo() {
      _this.time = 0;
      _this.ansId = "";
      let detailObj = _this.detail;
      detailObj.extend.extend_MA.forEach((item, index) => {
        item.select = 0;
      });
      _this.huida = false;
      _this.status = false;
      _this.detail = detailObj;
      clearInterval(intervalTime);
      _this.daojishi();
    },

    // 开始计时
    daojishi() {
      intervalTime = setInterval(() => {
        _this.time = _this.time + 1;
        _this.datiTime = _this.formateSeconds(_this.time);
      }, 1000);
    },

    //将秒转化为时分秒
    formateSeconds(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },

    // 提交答案
    submitTopic() {
      var params = {
        topic_id: _this.id,
        content: _this.ansId,
        time: _this.time,
      };
      if (localStorage.getItem("token")) {
        // if (_this.ansId != "") {
        _this.$axios
          .post("exercise.topic/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.obj = res.data;
              _this.topicExerciseId = res.data.topicExerciseId;
              let detailObj = _this.detail;
              detailObj.extend.extend_MA.forEach((item, index) => {
                if (_this.ansId == item.key && item.daan == 1) {
                  // 选择的答案是正确答案

                  item.select = 2;
                } else if (_this.ansId == item.key && item.daan == 0) {
                  item.select = 3;
                } else if (item.daan == 1) {
                  item.select = 2;
                }
              });

              _this.detail = detailObj;

              _this.huida = true;
              _this.$message.success("答题已完成");
              _this.status = true;
              clearInterval(intervalTime);
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
        // }else {
        //     _this.$message.error("您还没答题，请先进行答题");
        // }
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },

    // 选择答案
    selectDaan(i) {
      let obj = _this.detail,
        strList = [];
      obj.extend.extend_MA.forEach((item, index) => {
        if (index == i && item.select == 0) {
          item.select = 1;
        } else if (index == i && item.select == 1) {
          item.select = 0;
        }
        if (item.select == 1) {
          strList.push(item.key);
        }
      });
      _this.detail = obj;
      _this.ansId = strList.join(",");
      console.log(_this.ansId);
    },
    // 微信分享复制链接
    copyToH5(){

    // console.log('当前的domain', item);return
      let pinyin = 'MCM_R';
      let tips = pinyin+" "+_this.detail.number+" "+" PTE易王炸AI评分 ";
      this.$copyText(tips+" "+window.location.origin+"/H5Detail?exericise_id="+_this.topicExerciseId+"&topic_id="+ _this.detail.id+"&pinyin="+pinyin+"&title="+encodeURIComponent(_this.detail.name)+"&page=questionType6");
      this.$message.success("地址复制成功")
    },

    // 获取详情
    getDetail() {
      var params = {
        topic_id: _this.id,
      };
      _this.$axios
        .post("exercise.topic/getTopicInfo", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            res.data.extend.extend_MA.forEach((item) => {
              item.select = 0;
            });
            _this.detail = res.data;
            _this.bofangStatus = false;
            _this.mouseStatus = false;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      // _this.title = _this.$route.query.name;
      _this.type = _this.$route.query.type;
      _this.getDetail();
    },
  },
};
</script>


<style>
</style>
